import React, {useEffect, useState} from "react";
import PropTypes from 'prop-types';
import {connect} from "react-redux";
import {bindActionCreators} from "redux";
import * as mobileTowersActions from '../../../actions/mobileTowers/mobileTowersActions';
import {appFeatures} from "../../../constants/appConfig";
import PACSSearchHeader from "./PACSSearchHeader";
import {FaBars} from "react-icons/fa";
import {getFileUri, goToPlacePage, goToTowerPage} from "../../../utils/navigation";
import * as regionsActions from '../../../actions/mobileTowers/regionsActions';
import {Link, useNavigate} from "react-router-dom";

function PACSHeader({actions, pageTitle, mobileTower, isTowerPage, toggleSideNav, regions}) {
    const [searchText, setSearchText] = useState('');
    const navigate = useNavigate();

    // console.log('regions', regions);
    let placeId = 0;
    const place = regions.map[placeId];
    let titleDiv;
    let breadcrumbs = [];
    let imageUrl;

    if (mobileTower && mobileTower.company && mobileTower.company.files && mobileTower.company.files.length > 0) {
        imageUrl = getFileUri(mobileTower.company.files[0].id);
    } else if (mobileTower && mobileTower.clientLogo && mobileTower.clientLogo.length > 0) {
        imageUrl = getFileUri(mobileTower.clientLogo[0].id);
    } else {
        imageUrl = '/img/newMobile-towers/client_logo.jpg';
    }

    if (mobileTower && mobileTower.addresses.length > 0) {
        const [address] = mobileTower.addresses;
        const fragments = [];

        if (address.country) {
            fragments.push((
                <a
                    key={address.country.id}
                    href={null}
                    className="cursor-pointer"
                    onClick={() => {
                        goToPlacePage(navigate, address.country);
                    }}
                >
                    {address.country.name}
                </a>
            ));
        }

        if (address.state) {
            fragments.push((
                <a
                    key={address.state.id}
                    href={null}
                    className="cursor-pointer"
                    onClick={() => {
                        goToPlacePage(navigate, address.state);
                    }}
                >
                    {address.state.name}
                </a>
            ));
        }

        if (address.zone) {
            fragments.push((
                <a
                    key={address.zone.id}
                    href={null}
                    className="cursor-pointer"
                    onClick={() => {
                        goToPlacePage(navigate, address.zone);
                    }}
                >
                    {address.zone.name}
                </a>
            ));
        }

        if (address.city) {
            fragments.push((
                <a
                    key={address.city.id}
                    href={null}
                    className="cursor-pointer"
                    onClick={() => {
                        goToPlacePage(navigate, address.city);
                    }}
                >
                    {address.city.name}
                </a>
            ));
        } else if (address.village) {
            fragments.push((
                <a
                    key={address.village.id}
                    href={null}
                    className="cursor-pointer"
                    onClick={() => {
                        goToPlacePage(navigate, address.village);
                    }}
                >
                    {address.village.name}
                </a>
            ));
        }

        // breadcrumbStr = fragments.join(' / ');
        fragments.forEach((fragment, index) => {
            if (breadcrumbs.length > 0) {
                breadcrumbs.push((
                    <span key={`gap-${index}`}> / </span>
                ));
            }

            breadcrumbs.push(fragment);
        });
    }

    if (isTowerPage) {
        titleDiv = (
            <div className="grow">
                {breadcrumbs && (
                    <p className="text-xs text-white">
                        {breadcrumbs}
                    </p>
                )}
                <p className="text-white font-bold text-xl">{mobileTower ? mobileTower.siteIdStr : ''}</p>
            </div>
        );
    } else {
        titleDiv = (
            <div className="grow">
                <p className="text-xl font-bold text-white">{pageTitle}</p>
            </div>
        );
    }

    return (
        <div>
            <div className="bg-brand border">

                <div className="p-2 flex flex-row items-center justify-between">
                    <button onClick={toggleSideNav} className="inline pl-2 pr-2 mr-2 py-2">
                        <FaBars className="w-4 h-4 text-white"/>
                    </button>

                    {titleDiv}

                    <div className="basis-1/4">
                        {appFeatures.search && (
                            <PACSSearchHeader/>
                        )}
                    </div>

                    {isTowerPage && (
                        <div>
                            {/* <img src="/img/newMobile-towers/client_logo.jpg" className="w-32 h-auto mr-2 inline" /> */}
                            {/* {mobileTower && (
                                <img
                                    alt={`Client Image`}
                                    src={imageUrl}
                                    className="w-auto h-12 mr-2 inline"
                                    style={{display: 'inline-block'}}
                                />
                            )} */}
                        </div>
                    )}
                </div>
            </div>
        </div>
    );
}

PACSHeader.defaultProps = {
    actions: {},
    regions: {},


};

PACSHeader.propTypes = {
    actions: PropTypes.object,
    regions: PropTypes.object,
};

const mapStateToProps = state => ({
    mobileTowers: state.mobileTowers,
    regions: state.regions,
});

const mapDispatchToProps = dispatch => ({
    actions: bindActionCreators(Object.assign({}, mobileTowersActions, regionsActions), dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(PACSHeader);

