import React, {useState, useEffect} from 'react';
import PropTypes from 'prop-types';
import LoadingIcon from "./LoadingIcon";

function Modal({
                   buttons,
                   cancelText,
                   cancelClassName,
                   className,
                   title,
                   children,
                   closeOnEscapeKey,
                   showCancel,
                   showCloseInHeader,
                   showHeader,
                   showFooter,
                   toggleModal,
                   noPadding
               }) {

    useEffect(() => {
        const keyPressEvent = (e) => {
            if (closeOnEscapeKey && e.keyCode === 27) {
                toggleModal(e);
            }
        };

        document.addEventListener("keyup", keyPressEvent);
        return () => {
            document.removeEventListener("keyup", keyPressEvent);
        };
    }, []);

    const actionButtons = [];

    buttons.forEach((buttonConfig, index) => {
        let icon = null;
        if (buttonConfig.loading) {
            icon = (<LoadingIcon className="mr-2"/>);
        }

        let classes = '';
        if (buttonConfig.type === 'dropdown') {
            const dropdownItems = [];

            buttonConfig.items.forEach((dropdownItem) => {
                dropdownItems.push((
                    <li>
                        <a href={null} onClick={dropdownItem.onClick}>
                            {dropdownItem.label}
                        </a>
                    </li>
                ));
            });

            classes = `dropdown ${buttonConfig.className}`;
            actionButtons.push((
                <div className={classes}>
                    <label tabIndex="0" className="btn m-1">
                        {icon} {buttonConfig.label}
                    </label>
                    <ul
                        tabIndex="0"
                        className="dropdown-content menu p-2 shadow bg-base-100 rounded-box w-52"
                    >
                        {dropdownItems}
                    </ul>
                </div>
            ));
        } else {
            classes = `btn ${buttonConfig.className}`;
            actionButtons.push((
                <button
                    key={index}
                    className={classes}
                    onClick={buttonConfig.onClick}
                    disabled={!!buttonConfig.disabled}
                >
                    {icon}
                    {buttonConfig.label}
                </button>
            ));
        }
    });

    let btnCancel = null,
        btnClose = null,
        headerContents = null,
        footerContents = null,
        btnClosePopup = null;

    if (showCancel) {
        btnCancel = (
            <button className={`btn w-24 text-white ${cancelClassName}`} onClick={toggleModal}>
                {cancelText}
            </button>
        );
    }

    if (showCloseInHeader) {
        btnClose = (
            <a href={null} className="select-none cursor-pointer px-2 hover:bg-error rounded-xl" onClick={toggleModal}>
                ×
            </a>
        );
    }

    if (showHeader) {
        headerContents = (
            <div className="modal-header flex justify-between bg-primary px-6 py-3 text-white">
                <h3 className="font-bold text-lg">
                    {title}
                </h3>

                {btnClose}
            </div>
        );
    }

    if (showFooter) {
        footerContents = (
            <div>
                <div className="h-px w-full bg-gray-300 mt-6"></div>

                <div className="modal-action px-6 pb-6 flex justify-between">
                    <div>
                        {actionButtons}
                    </div>

                    <div className="">
                        {btnCancel}
                    </div>
                </div>
            </div>
        );
    }

    if (!showHeader && !showFooter) {
        btnClosePopup = (
            <a
                href={null}
                className="select-none cursor-pointer px-2 rounded-xl btn btn-xs rounded-full hover:text-white btn-close-popup bg-white"
                onClick={toggleModal}
            >
                ×
            </a>
        );
    }

    const classes = `modal modal-open ${className}`;
    const modalContentClasses = `modal-content ${noPadding ? '' : 'px-4 pt-4'}`;

    return (
        <div className={classes}>
            <div className="modal-box p-0">
                {headerContents}
                {btnClosePopup}

                <div className={modalContentClasses}>
                    {children}
                </div>

                {footerContents}
            </div>
        </div>
    );
}

Modal.defaultProps = {
    buttons: [],
    cancelText: 'CANCEL',
    cancelClassName: 'btn-error',
    children: [],
    className: '',
    closeOnEscapeKey: true,
    noPadding: false,
    showCancel: true,
    showCloseInHeader: true,
    showHeader: true,
    showFooter: true,
    title: 'Modal Title',
};

Modal.propTypes = {
    buttons: PropTypes.array,
    cancelText: PropTypes.string,
    cancelClassName: PropTypes.string,
    children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.element), PropTypes.element]),
    className: PropTypes.string,
    closeOnEscapeKey: PropTypes.bool,
    noPadding: PropTypes.bool,
    showCancel: PropTypes.bool,
    showCloseInHeader: PropTypes.bool,
    showHeader: PropTypes.bool,
    showFooter: PropTypes.bool,
    title: PropTypes.string,
    toggleModal: PropTypes.func.isRequired,
};

export default Modal;
