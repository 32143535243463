export function getTenantName(tenant) {
    let tenantModelName = [tenant.make, tenant.model].filter((t) => t && t.length > 0).join(' ');
    tenantModelName = tenantModelName.length > 0 ? `(${tenantModelName})` : '';

    // console.log('tmn', tenantModelName)
    return `${tenant.antennaOperator} - ${tenant.antennaType} on ${tenant.legName} ${tenantModelName}`;
}

export function getWidthDimensionType(mobileTower) {
    let dimensionType = '';
    if (mobileTower.towerConfiguration === "3 Legged Angular" || mobileTower.towerConfiguration === "4 Legged Angular") {
        dimensionType = "(b/b)";
    } else {
        dimensionType = "(c/c)";
    }

    return dimensionType;
}

export function getLegsArr(mobileTower) {
    const legs = ['L1', 'L2', 'L3'];

    if (mobileTower.towerConfiguration === '4 Legged Angular') {
        legs.push('L4');
    } else if (mobileTower.towerConfiguration === '6 Legged Delta') {
        legs.push('L4');
        legs.push('L5');
        legs.push('L6');
    }

    return legs;
}

export function getK1(designLife, designWindSpeed) {
    if (designLife === 5) {
        if (designWindSpeed === 33) {
            return 0.82;
        }

        if (designWindSpeed === 39) {
            return 0.76;
        }

        if (designWindSpeed === 44) {
            return 0.73;
        }

        if (designWindSpeed === 47) {
            return 0.71;
        }

        if (designWindSpeed === 50) {
            return 0.70;
        }

        if (designWindSpeed === 55) {
            return 0.67;
        }
    }

    if (designLife === 25) {
        if (designWindSpeed === 33) {
            return 0.94;
        }

        if (designWindSpeed === 39) {
            return 0.92;
        }

        if (designWindSpeed === 44) {
            return 0.91;
        }

        if (designWindSpeed === 47) {
            return 0.90;
        }

        if (designWindSpeed === 50) {
            return 0.90;
        }

        if (designWindSpeed === 55) {
            return 0.89;
        }
    }

    if (designLife === 50) {
        // Same value for all wind speed
        return 1.0;
    }

    if (designLife === 100) {
        if (designWindSpeed === 33) {
            return 1.05;
        }

        if (designWindSpeed === 39) {
            return 1.06;
        }

        if (designWindSpeed === 44 || designWindSpeed === 47) {
            return 1.07;
        }

        if (designWindSpeed === 50 || designWindSpeed === 55) {
            return 1.08;
        }
    }

    return '-';
}

export function getK2(designTerrainCategory, totalHeight) {
    // console.log('t', totalHeight)
    if (designTerrainCategory === 'TC1') {
        if (totalHeight <= 10) {
            return 1.05;
        } else if (totalHeight > 10 && totalHeight <= 15) {
            return 1.05 + ((totalHeight - 10) * ((1.09 - 1.05) / (15 - 10)));
        } else if (totalHeight > 15 && totalHeight <= 20) {
            return 1.09 + ((totalHeight - 15) * ((1.12 - 1.09) / (20 - 15)));
        } else if (totalHeight > 20 && totalHeight <= 30) {
            return 1.12 + ((totalHeight - 20) * ((1.15 - 1.12) / (30 - 20)));
        } else if (totalHeight > 30 && totalHeight <= 50) {
            return 1.15 + ((totalHeight - 30) * ((1.20 - 1.15) / (50 - 30)));
        } else if (totalHeight > 50 && totalHeight <= 100) {
            return 1.20 + ((totalHeight - 50) * ((1.26 - 1.20) / (100 - 50)));
        } else if (totalHeight > 100 && totalHeight <= 150) {
            return 1.26 + ((totalHeight - 100) * ((1.30 - 1.26) / (150 - 100)));
        } else if (totalHeight > 150 && totalHeight <= 200) {
            return 1.30 + ((totalHeight - 150) * ((1.32 - 1.30) / (200 - 150)));
        } else if (totalHeight > 200 && totalHeight <= 250) {
            return 1.32 + ((totalHeight - 200) * ((1.34 - 1.32) / (250 - 200)));
        } else if (totalHeight > 250 && totalHeight <= 300) {
            return 1.34 + ((totalHeight - 250) * ((1.35 - 1.34) / (300 - 250)));
        } else {
            return 1.35;
        }
    }

    if (designTerrainCategory === 'TC2') {
        if (totalHeight <= 10) {
            return 1.00;
        } else if (totalHeight > 10 && totalHeight <= 15) {
            return 1.0 + ((totalHeight - 10) * ((1.05 - 1.00) / (15 - 10)));
        } else if (totalHeight > 15 && totalHeight <= 20) {
            return 1.05 + ((totalHeight - 15) * ((1.07 - 1.05) / (20 - 15)));
        } else if (totalHeight > 20 && totalHeight <= 30) {
            return 1.07 + ((totalHeight - 20) * ((1.12 - 1.07) / (30 - 20)));
        } else if (totalHeight > 30 && totalHeight <= 50) {
            return 1.12 + ((totalHeight - 30) * ((1.17 - 1.12) / (50 - 30)));
        } else if (totalHeight > 50 && totalHeight <= 100) {
            return 1.17 + ((totalHeight - 50) * ((1.24 - 1.17) / (100 - 50)));
        } else if (totalHeight > 100 && totalHeight <= 150) {
            return 1.24 + ((totalHeight - 100) * ((1.28 - 1.24) / (150 - 100)));
        } else if (totalHeight > 150 && totalHeight <= 200) {
            return 1.28 + ((totalHeight - 150) * ((1.30 - 1.28) / (200 - 150)));
        } else if (totalHeight > 200 && totalHeight <= 250) {
            return 1.30 + ((totalHeight - 200) * ((1.32 - 1.30) / (250 - 200)));
        } else if (totalHeight > 250 && totalHeight <= 300) {
            return 1.32 + ((totalHeight - 250) * ((1.34 - 1.32) / (300 - 250)));
        } else if (totalHeight > 300 && totalHeight <= 350) {
            return 1.34 + ((totalHeight - 300) * ((1.34 - 1.32) / (350 - 300)));
        } else {
            return 1.35;
        }
    }

    if (designTerrainCategory === 'TC3') {
        if (totalHeight <= 10) {
            return 0.91;
        } else if (totalHeight > 10 && totalHeight <= 15) {
            return 0.91 + ((totalHeight - 10) * ((0.97 - 0.91) / (15 - 10)));
        } else if (totalHeight > 15 && totalHeight <= 20) {
            return 0.97 + ((totalHeight - 15) * ((1.01 - 0.97) / (20 - 15)));
        } else if (totalHeight > 20 && totalHeight <= 30) {
            return 1.01 + ((totalHeight - 20) * ((1.06 - 1.01) / (30 - 20)));
        } else if (totalHeight > 30 && totalHeight <= 50) {
            return 1.06 + ((totalHeight - 30) * ((1.12 - 1.06) / (50 - 30)));
        } else if (totalHeight > 50 && totalHeight <= 100) {
            return 1.12 + ((totalHeight - 50) * ((1.20 - 1.12) / (100 - 50)));
        } else if (totalHeight > 100 && totalHeight <= 150) {
            return 1.20 + ((totalHeight - 100) * ((1.24 - 1.20) / (150 - 100)));
        } else if (totalHeight > 150 && totalHeight <= 200) {
            return 1.24 + ((totalHeight - 150) * ((1.27 - 1.24) / (200 - 150)));
        } else if (totalHeight > 200 && totalHeight <= 250) {
            return 1.27 + ((totalHeight - 200) * ((1.29 - 1.27) / (250 - 200)));
        } else if (totalHeight > 250 && totalHeight <= 300) {
            return 1.29 + ((totalHeight - 250) * ((1.31 - 1.29) / (300 - 250)));
        } else if (totalHeight > 300 && totalHeight <= 350) {
            return 1.31 + ((totalHeight - 300) * ((1.32 - 1.31) / (350 - 300)));
        } else if (totalHeight > 350 && totalHeight <= 400) {
            return 1.32 + ((totalHeight - 350) * ((1.34 - 1.32) / (400 - 350)));
        } else if (totalHeight > 400 && totalHeight <= 450) {
            return 1.34 + ((totalHeight - 400) * ((1.35 - 1.34) / (450 - 400)));
        } else {
            return 1.35;
        }
    }

    if (designTerrainCategory === 'TC4') {
        if (totalHeight <= 20) {
            return 0.80;
        } else if (totalHeight > 20 && totalHeight <= 30) {
            return 0.80 + ((totalHeight - 20) * ((0.97 - 0.80) / (30 - 20)));
        } else if (totalHeight > 30 && totalHeight <= 50) {
            return 0.97 + ((totalHeight - 30) * ((1.10 - 0.97) / (50 - 30)));
        } else if (totalHeight > 50 && totalHeight <= 100) {
            return 1.10 + ((totalHeight - 50) * ((1.20 - 1.10) / (100 - 50)));
        } else if (totalHeight > 100 && totalHeight <= 150) {
            return 1.20 + ((totalHeight - 100) * ((1.24 - 1.20) / (150 - 100)));
        } else if (totalHeight > 150 && totalHeight <= 200) {
            return 1.24 + ((totalHeight - 150) * ((1.27 - 1.24) / (200 - 150)));
        } else if (totalHeight > 200 && totalHeight <= 250) {
            return 1.27 + ((totalHeight - 200) * ((1.28 - 1.27) / (250 - 200)));
        } else if (totalHeight > 250 && totalHeight <= 300) {
            return 1.28 + ((totalHeight - 250) * ((1.30 - 1.28) / (300 - 250)));
        } else if (totalHeight > 300 && totalHeight <= 350) {
            return 1.30 + ((totalHeight - 300) * ((1.31 - 1.30) / (350 - 300)));
        } else if (totalHeight > 350 && totalHeight <= 400) {
            return 1.31 + ((totalHeight - 350) * ((1.32 - 1.31) / (400 - 350)));
        } else if (totalHeight > 400 && totalHeight <= 450) {
            return 1.32 + ((totalHeight - 400) * ((1.33 - 1.32) / (450 - 400)));
        } else if (totalHeight > 450 && totalHeight <= 500) {
            return 1.33 + ((totalHeight - 450) * ((1.34 - 1.33) / (450 - 400)));
        } else {
            return 1.34;
        }
    }

    return '-';
}

export function getApplicableDefectIds(mobileTower, defects, criticalityGroup = '') {
    return defects.ids.filter((defectId) => {
        const defect = defects.map[defectId];

        // Defect ID 9 is NOT applicable for Angular Towers
        // Flange gaps > 9mm
        if (defect.id === 9) {
            if (mobileTower.towerConfiguration === '3 Legged Angular' || mobileTower.towerConfiguration === '4 Legged Angular') {
                return false;
            }
        }

        if (!criticalityGroup) {
            return true;
        }

        return (defect.criticalityGroup === criticalityGroup);
    });
}

export function getReductionFactor(antennaLength, antennaBreadth) {
    // Flat Plate perpendicular Reduction Factor.
    let lb = (antennaLength / antennaBreadth); // (I/b = length / width) 

    if (lb <= 2) {
        return 0.62;
    } else if (lb > 2 && lb <= 5) {
        return 0.62 + (((0.66 - 0.62) * (lb - 2)) / (5 - 2));
    } else if (lb > 5 && lb <= 10) {
        return 0.66 + (((0.69 - 0.66) * (lb - 5)) / (10 - 5));
    } else if (lb > 10 && lb <= 20) {
        return 0.69 + (((0.81 - 0.69) * (lb - 10)) / (20 - 10));
    } else if (lb > 20 && lb <= 40) {
        return 0.81 + (((0.87 - 0.81) * (lb - 20)) / (40 - 20));
    } else if (lb > 40 && lb <= 50) {
        return 0.87 + (((0.90 - 0.87) * (lb - 40)) / (50 - 40));
    } else if (lb > 50 && lb <= 100) {
        return 0.90 + (((0.95 - 0.90) * (lb - 50)) / (100 - 50));
    } else if (lb > 100) {
        return 1.00;
    }
}

export function getTenantEPA(tenant) {
    const {antennaLength, antennaBreadth, antennaType, tenantType, mwDiameter} = tenant;

    let forceCoefficient = 2; // for GSM && RRU Antenna
    let microwaveForceCoefficient; // micro-wave antenna
    let epa = 0;
    let qty = tenant.quantity ? tenant.quantity : 1;

    if (antennaType === 'GSM' || antennaType === 'RRU') {
        if (antennaLength === 0 || antennaBreadth === 0) {
            return 0;
        }

        epa = qty * antennaLength * antennaBreadth * forceCoefficient * getReductionFactor(antennaLength, antennaBreadth);
    } else if (antennaType === 'Micro-wave') {
        if (tenantType !== 'Existing') {
            // For SR / Spare Tenant
            microwaveForceCoefficient = 1.2;
            epa = Math.PI * Math.pow(mwDiameter, 2) * microwaveForceCoefficient * (qty / 4);
        } else {
            // For Existing Tenant
            microwaveForceCoefficient = 0.92;
            epa = qty * Math.pow(mwDiameter, 2) * microwaveForceCoefficient * 0.7854;
        }
    }

    return epa;
}

export function getKa(bottomWidth, topWidth, panelHeight) {
    const areaOfPanel = (topWidth + bottomWidth) * 0.5 * panelHeight;

    if (areaOfPanel <= 10) {
        return 1;
    } else if (areaOfPanel <= 25) {
        return 0.9;
    } else if (areaOfPanel <= 100) {
        return 0.8;
    } else {
        return '-';
    }
}

export function getEPALadder(cableLadder) {
    const {
        locationClimbingLadder,
        ladderLegAngle,
        rungsDiameter,
        ladderWidthClimbingLadder,
        rungsSpacing,
        safetyHoopFlat,
        safetyHoopFlatSpacing,
        verticalFlat,
        verticalFlat1,
    } = cableLadder;

    let exposedAreaOfLadderLegsRungs = 0, exposedAreaOfSafetyHoop = 0, exposedAreaOfVerticalFlat = 0;

    exposedAreaOfLadderLegsRungs = 2 * ladderLegAngle * 1.63 + (rungsDiameter * (ladderWidthClimbingLadder / rungsSpacing));
    // console.log('exposedAreaLadderLegs', exposedAreaOfLadderLegsRungs);

    exposedAreaOfSafetyHoop = 1.63 * safetyHoopFlat * 0.375 * 2 / safetyHoopFlatSpacing;

    // console.log('exposedAreaSafetyHoop', exposedAreaOfSafetyHoop);
    exposedAreaOfVerticalFlat = 1.63 * (verticalFlat + (2 * verticalFlat1));
    // console.log('exposedVertical', exposedAreaOfVerticalFlat);
    let exposedAreaLadder = 0, sheildingEffect = 0;

    if (locationClimbingLadder === 'Internal' || locationClimbingLadder === 'Corner') {
        sheildingEffect = 0.75;
        exposedAreaLadder = sheildingEffect * (exposedAreaOfLadderLegsRungs + exposedAreaOfSafetyHoop + exposedAreaOfVerticalFlat);
    } else if (locationClimbingLadder === 'External') {
        sheildingEffect = 1;
        exposedAreaLadder = sheildingEffect * (exposedAreaOfLadderLegsRungs + exposedAreaOfSafetyHoop + exposedAreaOfVerticalFlat);
    }
    // console.log('shf', sheildingEffect);
    // console.log('exposedAreaOfLadder', exposedAreaLadder);
    return exposedAreaLadder;

}

export function copyForStaad(data, direction, towerMode) {
    // Prepare text
    const valuesToCopy = data.map((row) => {
        if (direction === 'X') {
            if (towerMode === 'existing') {
                return row.wlX;
            } else if (towerMode === 'sr') {
                return row.wlX_Sr;
            } else if (towerMode === 'spare') {
                return row.wlX_Spare;
            } else {
                return '';
            }
        } else if (direction === 'Z') {
            if (towerMode === 'existing') {
                return row.wlZ;
            } else if (towerMode === 'sr') {
                return row.wlZ_Sr;
            } else if (towerMode === 'spare') {
                return row.wlZ_Spare;
            } else {
                return '';
            }
        } else if (direction === '-Z') {
            if (towerMode === 'existing') {
                return row.wlMinusZ;
            } else if (towerMode === 'sr') {
                return row.wlMinusZ_Sr;
            } else if (towerMode === 'spare') {
                return row.wlMinusZ_Spare;
            } else {
                return '';
            }
        } else {
            return '';
        }
    });

    const copyText = valuesToCopy.join('\n');
    window.navigator.clipboard.writeText(copyText);
}

export function getPanelWiseMembers(members) {
    const membersPanelWise = {
        leg: {},
        diagonal: {},
        horizontal: {},
        secondary: {},
    };

    members.forEach((member) => {
        if (member.memberType === 'Leg') {
            membersPanelWise.leg[member.panelId] = member;
        } else if (member.memberType === 'Diagonal') {
            membersPanelWise.diagonal[member.panelId] = member;
        } else if (member.memberType === 'Horizontal') {
            membersPanelWise.horizontal[member.panelId] = member;
        } else if (member.memberType === 'Secondary') {
            membersPanelWise.secondary[member.panelId] = member;
        }
    });

    return membersPanelWise;
}

export function getUpdatedPanelDimensions(panels, members) {
    const panelDims = {};
    const membersPanelWise = getPanelWiseMembers(members);

    let topWidth = 0, bottomWidth = 0;

    panels.forEach((panel) => {
        const { id: panelId } = panel;
        const legMember = membersPanelWise.leg[panelId] ? membersPanelWise.leg[panelId] : null;

        if (legMember) {
            if (topWidth === 0) {
                bottomWidth = panel.bottomWidth + (legMember.diameter / 1000);
            } else {
                bottomWidth = topWidth;
            }

            topWidth = panel.topWidth + (legMember.diameter / 1000);
        }

        panelDims[panelId] = {
            bottomWidth,
            topWidth,
        };
    });

    return panelDims;
}

export function getApplicableMember(panel, membersPanelWise, memberType) {
    if (!panel) {
        return null;
    }

    const memberTypeKey = memberType.toLowerCase();
    if (!membersPanelWise[memberTypeKey]) {
        return null;
    }

    if (!membersPanelWise[memberTypeKey][panel.id]) {
        return null;
    }

    return membersPanelWise[memberTypeKey][panel.id];
}

export function getProfileTypeSymbol(profileType) {
    if (profileType === "Circular"){
        return "Ø";
    } else if(profileType === "Angular") {
        return "L";
    } else if (profileType === "Double Angular") {
        return "DL";
    } else if (profileType === "Star Angular") {
        return "SL";
    } else if (profileType === "Exp Angular") {
        return "^L";
    } else {
        return "";
    }
}

export function getDefaultProfileType(towerConfiguration) {
    if (towerConfiguration === "4 Legged Angular" || towerConfiguration === "3 Legged Angular") {
        return "L - Angular";
    } else if (towerConfiguration === "3 Legged Hybrid") {
        return "";
    } else if (towerConfiguration === "3 Legged Tubular") {
        return "Ø - Circular";
    } else if (towerConfiguration === "6 Legged Delta") {
        return "";
    } else {
        return "";
    }
}

export function isTubularTower(mobileTower) {
    let { towerConfiguration } = mobileTower;
    if (!towerConfiguration) {
        towerConfiguration = '';
    }

    return towerConfiguration.indexOf('Tubular') !== -1;
}

export function isAngularTower(mobileTower) {
    let { towerConfiguration } = mobileTower;
    if (!towerConfiguration) {
        towerConfiguration = '';
    }

    return towerConfiguration.indexOf('Angular') !== -1;
}

export function isHybridTower(mobileTower) {
    let { towerConfiguration } = mobileTower;
    if (!towerConfiguration) {
        towerConfiguration = '';
    }

    return towerConfiguration.indexOf('Hybrid') !== -1;
}

export function isDeltaTower(mobileTower) {
    let { towerConfiguration } = mobileTower;
    if (!towerConfiguration) {
        towerConfiguration = '';
    }

    return towerConfiguration.indexOf('Delta') !== -1;
}

export function getYieldStress(spec) {
    if (spec === 'MS Steel as per IS 1161 (Yst 310)') {
        return 310;
    } else if (spec === 'MS Steel as per IS 2062 (E250)') {
        return 250;
    } else if (spec === 'MS Steel as per IS 2062 (E350)') {
        return 350;
    } else if (spec === 'MS Steel as per IS 1161 (Yst 240)') {
        return 240;
    } else {
        return 0;
    }
}
