import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import SelectBox from "../Utilities/Forms/SelectBox";
import * as companiesActions from "../../../actions/companiesActions";

function CompanySelection({ actions, companies, label, value, onChange}) {
  const [companiesRequested, setCompaniesRequested] = useState(false);

  useEffect(() => {
    if (!companies.loaded && !companiesRequested) { 
      setCompaniesRequested(true);
      actions.getCompanies();
    }
  }, []);

  let options = [];

  options.push({
    label: "All",
    value: 0,
  });

  if (companies && companies.ids.length) {
      companies.ids.forEach((compId) => {
        let company = companies.map[compId];

        options.push({
            label: company.name,
            value: compId,
        });
      });
  }

  return (
    <div className="py-2 text-black flex flex-row">
      <div className="basis-full">
        <SelectBox
          label={label}
          value={value}
          options={options}
          onChange={onChange}
        />
      </div>
    </div>
  );
}

CompanySelection.defaultProps = {
  actions: {},
  label: "",
  value: 0,
  companies: {},
};

CompanySelection.propTypes = {
  actions: PropTypes.object,
  label: PropTypes.string,
  companies: PropTypes.object,
  value: PropTypes.number,
  onChange: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
  companies: state.companies,
});

const mapDispatchToProps = (dispatch) => ({
  actions: bindActionCreators(Object.assign({}, companiesActions), dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(CompanySelection);
