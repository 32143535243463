import cloneDeep from 'lodash/cloneDeep';
import initialState from '../../store/initialState';
import {
   GET_PLACES_STARTING,
   GET_PLACES_SUCCESS,
   GET_PLACES_ERROR,
   ADD_PLACE_SUCCESS,
   UPDATE_PLACE_SUCCESS,
   GET_INACTIVE_PLACES_SUCCESS,
   DEACTIVATE_PLACE_SUCCESS,
   ACTIVATE_PLACE_SUCCESS,
} from '../../constants/actionTypes';

const sortByOrder=(newState)=>{

}

export default function placesReducer(state=initialState.places,action){
switch(action.type){
    case GET_PLACES_STARTING: {
        const newState = cloneDeep(state);
        newState.loading = true;
        newState.loaded = false;
        newState.error = null;
        return newState;
    }

    case GET_PLACES_SUCCESS: {
        const newState = cloneDeep(state);
        const ids = [];

        action.data.places.forEach((place) => {
            const placeId = place.id;
            ids.push(placeId);
            newState.map[placeId] = place;
        });

        console.log(ids);

        newState.ids = ids;
        newState.loading = false;
        newState.loaded = true;

        sortByOrder(newState);

        return newState;
    }

    case GET_INACTIVE_PLACES_SUCCESS: {
        const newState = cloneDeep(state);
        const inactiveIds = [];

        action.data.places.forEach((place) => {
            const placeId = place.id;
            inactiveIds.push(placeId);
            newState.map[placeId] = place;
        });

        newState.inactiveIds = inactiveIds;

        sortByOrder(newState);
        return newState;
    }

    case GET_PLACES_ERROR: {
        const newState = cloneDeep(state);
        newState.loading = false;
        newState.loaded = false;
        newState.error = action.err ? action.err : 'Failed to retrieve places.';
        return newState;
    }

    case ADD_PLACE_SUCCESS: {
        const newState = cloneDeep(state);
        const {place} = action.data;
        const placeId = place.id;

        // Push new place data
        newState.map[placeId] = place;
        newState.ids.push(placeId);

        // Sort by display order
        sortByOrder(newState);

        return newState;
    }

    case UPDATE_PLACE_SUCCESS: {
        const newState = cloneDeep(state);
        const {place} = action.data;
        const placeId = place.id;
        newState.map[placeId] = place;

        sortByOrder(newState);
        return newState;
    }

    case ACTIVATE_PLACE_SUCCESS: {
        const newState = cloneDeep(state);
        const {placeId} = action;
        newState.ids.push(placeId);

        newState.inactiveIds = newState.inactiveIds.filter((pId) => {
            return placeId !== pId;
        });
        if (newState.map[placeId]) {
            newState.map[placeId].isActive = 1;
        }
        sortByOrder(newState);
        return newState;
    }

    case DEACTIVATE_PLACE_SUCCESS: {
        const newState = cloneDeep(state);
        const {placeId} = action;
        newState.inactiveIds.push(placeId);

        newState.ids = newState.ids.filter((pId) => {
            return placeId !== pId;
        });

        if (newState.map[placeId]) {
            newState.map[placeId].isActive = 0;
        }

        sortByOrder(newState);
        return newState;
    }

    default: {
        return state;
    }
}
}
