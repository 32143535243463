import {
    SET_USER_DATA,
    SET_USER_DATA_NONE,

    GET_USER_INFO_STARTING,
    GET_USER_INFO_ERROR,
    GET_USER_INFO_SUCCESS,

    GET_USERS_STARTING,
    GET_USERS_SUCCESS,
    GET_USERS_ERROR,

    ADD_USER_STARTING,
    ADD_USER_SUCCESS,
    ADD_USER_ERROR,

    UPDATE_USER_DETAILS_STARTING,
    UPDATE_USER_DETAILS_SUCCESS,
    UPDATE_USER_DETAILS_ERROR,
} from '../constants/actionTypes'
import axios from '../axios';
import {
    GET_USER_INFO,
    GET_USERS,
    ADD_USER,
    UPDATE_USER,
    GET_USER_LOCATIONS,
} from '../constants/apiUrls';

export function getUserInfoStarting() {
    return {type: GET_USER_INFO_STARTING};
}

export function getUserInfoSuccess(data) {
    return {type: GET_USER_INFO_SUCCESS, data};
}

export function getUserInfoError(err) {
    return {type: GET_USER_INFO_ERROR, err};
}

export function getUserInfo() {
    return (dispatch) => {
        dispatch(getUserInfoStarting());

        axios.get(GET_USER_INFO).then((response) => {
            if (response.status === 200 && response.data.success && response.data.user) {
                dispatch(getUserInfoSuccess(response.data));
                return;
            }

            const err = (response && response.data && response.data.error) ?
                response.data.error : 'Failed to verify user token';
            dispatch(getUserInfoError(err));
        }).catch((err) => {
            const error = (err.response && err.response.data && err.response.data.error) ?
                err.response.data.error : err.message;
            dispatch(getUserInfoError(error));
        });
    };
}

export function setUserData(userJson, tokenJson) {
    return {
        type: SET_USER_DATA,
        userData: userJson,
        token: tokenJson,
    };
}

export function setUserDataUnavailable() {
    return {type: SET_USER_DATA_NONE};
}

export function getUsersStarting() {
    return {type: GET_USERS_STARTING};
}

export function getUsersSuccess(data) {
    return {type: GET_USERS_SUCCESS, data};
}

export function getUsersError(err) {
    return {type: GET_USERS_ERROR, err};
}

export function getUsers(postData) {
    return (dispatch) => {
        dispatch(getUsersStarting());

        axios.post(GET_USERS, postData).then((response) => {
            if (response.status === 200 && response.data.success) {
                dispatch(getUsersSuccess(response.data));
                return;
            }

            const err = (response && response.data && response.data.error) ?
                response.data.error : 'Failed to get user data';
            dispatch(getUsersError(err));
        }).catch((err) => {
            const error = (err.response && err.response.data && err.response.data.error) ?
                err.response.data.error : err.message;
            dispatch(getUsersError(error));
        });
    };
}

export function addUserStarting() {
    return {type: ADD_USER_STARTING};
}

export function addUserSuccess(data) {
    return {type: ADD_USER_SUCCESS, data};
}

export function addUserError(err) {
    return {type: ADD_USER_ERROR, err};
}

export function addUser(postData) {
    return (dispatch) => {
        dispatch(addUserStarting());

        axios.post(ADD_USER, postData).then((response) => {
            if (response.status === 200 && response.data.success) {
                dispatch(addUserSuccess(response.data));
                return;
            }

            const err = (response && response.data && response.data.error) ?
                response.data.error : 'Failed to add user';
            dispatch(addUserError(err));
        }).catch((err) => {
            const error = (err.response && err.response.data && err.response.data.error) ?
                err.response.data.error : err.message;
            dispatch(addUserError(error));
        });
    };
}

export function updateUserDetailsStarting(userId) {
    return {type: UPDATE_USER_DETAILS_STARTING, userId};
}

export function updateUserDetailsSuccess(userId, data) {
    return {type: UPDATE_USER_DETAILS_SUCCESS, userId, data};
}

export function updateUserDetailsError(userId, err) {
    return {type: UPDATE_USER_DETAILS_ERROR, userId, err};
}

export function updateUserDetails(userId, postData) {
    return (dispatch) => {
        dispatch(updateUserDetailsStarting(userId));

        const url = UPDATE_USER.replace('{userId}', userId);
        axios.put(url, postData).then((response) => {
            if (response.status === 200 && response.data.success) {
                dispatch(updateUserDetailsSuccess(userId, response.data));
                return;
            }

            const err = (response && response.data && response.data.error) ?
                response.data.error : 'Failed to update user details';
            dispatch(updateUserDetailsError(userId, err));
        }).catch((err) => {
            const error = (err.response && err.response.data && err.response.data.error) ?
                err.response.data.error : err.message;
            dispatch(updateUserDetailsError(userId, error));
        });
    };
}
