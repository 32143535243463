export const IGNORE_ACTION = "IGNORE_ACTION";
export const SOCKET_CONNECT_STARTING = "SOCKET_CONNECT_STARTING";
export const SOCKET_CONNECT_SUCCESS = "SOCKET_CONNECT_SUCCESS";
export const SOCKET_CONNECT_ERROR = "SOCKET_CONNECT_ERROR";
export const SOCKET_DISCONNECTED = "SOCKET_DISCONNECTED";
export const HEARTBEAT_SUCCESS = "HEARTBEAT_SUCCESS";

export const GET_USER_INFO_STARTING = "GET_USER_INFO_STARTING";
export const GET_USER_INFO_ERROR = "GET_USER_INFO_ERROR";
export const GET_USER_INFO_SUCCESS = "GET_USER_INFO_SUCCESS";

export const SAVE_PREV_ROUTE = "SAVE_PREV_ROUTE";
export const FORCE_USER_LOGIN = "FORCE_USER_LOGIN";

export const SET_USER_DATA = "SET_USER_DATA";
export const SET_USER_DATA_NONE = "SET_USER_DATA_NONE";

export const LOGIN_CHECK_STARTING = "LOGIN_CHECK_STARTING";
export const LOGIN_CHECK_ERROR = "LOGIN_CHECK_ERROR";
export const LOGIN_CHECK_SUCCESS = "LOGIN_CHECK_SUCCESS";

export const AUTO_LOGIN_STARTING = "AUTO_LOGIN_STARTING";
export const AUTO_LOGIN_ERROR = "AUTO_LOGIN_ERROR";
export const AUTO_LOGIN_SUCCESS = "AUTO_LOGIN_SUCCESS";

export const LOGOUT_STARTING = "LOGOUT_STARTING";
export const LOGOUT_ERROR = "LOGOUT_ERROR";
export const LOGOUT_SUCCESS = "LOGOUT_SUCCESS";

export const ENABLE_FULL_SCREEN = "ENABLE_FULL_SCREEN";
export const DISABLE_FULL_SCREEN = "DISABLE_FULL_SCREEN";

export const RESET_PASSWORD_STARTING = "RESET_PASSWORD_STARTING";
export const RESET_PASSWORD_SUCCESS = "RESET_PASSWORD_SUCCESS";
export const RESET_PASSWORD_ERROR = "RESET_PASSWORD_ERROR";

export const GET_ALARMS_STARTING = "GET_ALARMS_STARTING";
export const GET_ALARMS_SUCCESS = "GET_ALARMS_SUCCESS";
export const GET_ALARMS_ERROR = "GET_ALARMS_ERROR";

export const GET_ALERTS_STARTING = "GET_ALERTS_STARTING";
export const GET_ALERTS_SUCCESS = "GET_ALERTS_SUCCESS";
export const GET_ALERTS_ERROR = "GET_ALERTS_ERROR";

export const UPDATE_PASSWORD_STARTING = "UPDATE_PASSWORD_STARTING";
export const UPDATE_PASSWORD_SUCCESS = "UPDATE_PASSWORD_SUCCESS";
export const UPDATE_PASSWORD_ERROR = "UPDATE_PASSWORD_ERROR";

export const UPDATE_AUTHORIZATION_PASSWORD_STARTING =
  "UPDATE_AUTHORIZATION_PASSWORD_STARTING";
export const UPDATE_AUTHORIZATION_PASSWORD_SUCCESS =
  "UPDATE_AUTHORIZATION_PASSWORD_SUCCESS";
export const UPDATE_AUTHORIZATION_PASSWORD_ERROR =
  "UPDATE_AUTHORIZATION_PASSWORD_ERROR";

export const GET_USERS_STARTING = "GET_USERS_STARTING";
export const GET_USERS_SUCCESS = "GET_USERS_SUCCESS";
export const GET_USERS_ERROR = "GET_USERS_ERROR";

export const ADD_USER_STARTING = "ADD_USER_STARTING";
export const ADD_USER_SUCCESS = "ADD_USER_SUCCESS";
export const ADD_USER_ERROR = "ADD_USER_ERROR";

export const UPDATE_USER_DETAILS_STARTING = "UPDATE_USER_DETAILS_STARTING";
export const UPDATE_USER_DETAILS_SUCCESS = "UPDATE_USER_DETAILS_SUCCESS";
export const UPDATE_USER_DETAILS_ERROR = "UPDATE_USER_DETAILS_ERROR";

export const DELETE_ALL_ALARMS_STARTING = "DELETE_ALL_ALARMS_STARTING";
export const DELETE_ALL_ALARMS_SUCCESS = "DELETE_ALL_ALARMS_SUCCESS";
export const DELETE_ALL_ALARMS_ERROR = "DELETE_ALL_ALARMS_ERROR";

export const SAVE_REPORT_SETTINGS_STARTING = "SAVE_REPORT_SETTINGS_STARTING";
export const SAVE_REPORT_SETTINGS_SUCCESS = "SAVE_REPORT_SETTINGS_SUCCESS";
export const SAVE_REPORT_SETTINGS_ERROR = "SAVE_REPORT_SETTINGS_ERROR";

export const UPDATE_ROLE_STARTING = "UPDATE_ROLE_STARTING";
export const UPDATE_ROLE_SUCCESS = "UPDATE_ROLE_SUCCESS";
export const UPDATE_ROLE_ERROR = "UPDATE_ROLE_ERROR";

export const ACTIVATE_CATEGORY_STARTING = "ACTIVATE_CATEGORY_STARTING";
export const ACTIVATE_CATEGORY_SUCCESS = "ACTIVATE_CATEGORY_SUCCESS";
export const ACTIVATE_CATEGORY_ERROR = "ACTIVATE_CATEGORY_ERROR";

export const GET_CLIENT_INFO_STARTING = "GET_CLIENT_INFO_STARTING";
export const GET_CLIENT_INFO_SUCCESS = "GET_CLIENT_INFO_SUCCESS";
export const GET_CLIENT_INFO_ERROR = "GET_CLIENT_INFO_ERROR";

export const GET_ROLES_STARTING = "GET_ROLES_STARTING";
export const GET_ROLES_SUCCESS = "GET_ROLES_SUCCESS";
export const GET_ROLES_ERROR = "GET_ROLES_ERROR";

export const ADD_ROLE_STARTING = "ADD_ROLE_STARTING";
export const ADD_ROLE_SUCCESS = "ADD_ROLE_SUCCESS";
export const ADD_ROLE_ERROR = "ADD_ROLE_ERROR";

export const ACTIVATE_ROLE_STARTING = "ACTIVATE_ROLE_STARTING";
export const ACTIVATE_ROLE_SUCCESS = "ACTIVATE_ROLE_SUCCESS";
export const ACTIVATE_ROLE_ERROR = "ACTIVATE_ROLE_ERROR";

export const DEACTIVATE_ROLE_STARTING = "DEACTIVATE_ROLE_STARTING";
export const DEACTIVATE_ROLE_SUCCESS = "DEACTIVATE_ROLE_SUCCESS";
export const DEACTIVATE_ROLE_ERROR = "DEACTIVATE_ROLE_ERROR";

export const GET_ROLE_PERMISSIONS_STARTING = "GET_ROLE_PERMISSIONS_STARTING";
export const GET_ROLE_PERMISSIONS_SUCCESS = "GET_ROLE_PERMISSIONS_SUCCESS";
export const GET_ROLE_PERMISSIONS_ERROR = "GET_ROLE_PERMISSIONS_ERROR";

export const UPDATE_ROLE_PERMISSIONS_STARTING =
  "UPDATE_ROLE_PERMISSIONS_STARTING";
export const UPDATE_ROLE_PERMISSIONS_SUCCESS =
  "UPDATE_ROLE_PERMISSIONS_SUCCESS";
export const UPDATE_ROLE_PERMISSIONS_ERROR = "UPDATE_ROLE_PERMISSIONS_ERROR";

export const FILE_UPLOAD_STARTING = "FILE_UPLOAD_STARTING";
export const FILE_UPLOAD_SUCCESS = "FILE_UPLOAD_SUCCESS";
export const FILE_UPLOAD_ERROR = "FILE_UPLOAD_ERROR";
export const RESET_LAST_FILE_UPLOAD = "RESET_LAST_FILE_UPLOAD";

export const UPDATE_CLIENT_INFO_ERROR = "UPDATE_CLIENT_INFO_ERROR";
export const UPDATE_CLIENT_INFO_STARTING = "UPDATE_CLIENT_INFO_STARTING";
export const UPDATE_CLIENT_INFO_SUCCESS = "UPDATE_CLIENT_INFO_SUCCESS";

export const GET_AUDIT_LOG_DATA_STARTING = "GET_AUDIT_LOG_DATA_STARTING";
export const GET_AUDIT_LOG_DATA_SUCCESS = "GET_AUDIT_LOG_DATA_SUCCESS";
export const GET_AUDIT_LOG_DATA_ERROR = "GET_AUDIT_LOG_DATA_ERROR";

export const GET_REQUEST_NOTIFICATIONS_STARTING =
  "GET_REQUEST_NOTIFICATIONS_STARTING";
export const GET_REQUEST_NOTIFICATIONS_SUCCESS =
  "GET_REQUEST_NOTIFICATIONS_SUCCESS";
export const GET_REQUEST_NOTIFICATIONS_ERROR =
  "GET_REQUEST_NOTIFICATIONS_ERROR";

export const GET_MOBILE_TOWER_BY_ID_STARTING =
  "GET_MOBILE_TOWER_BY_ID_STARTING";
export const GET_MOBILE_TOWER_BY_ID_SUCCESS = "GET_MOBILE_TOWER_BY_ID_SUCCESS";
export const GET_MOBILE_TOWER_BY_ID_ERROR = "GET_MOBILE_TOWER_BY_ID_ERROR";

export const GET_MOBILE_TOWERS_STARTING = "GET_MOBILE_TOWERS_STARTING";
export const GET_MOBILE_TOWERS_SUCCESS = "GET_MOBILE_TOWERS_SUCCESS";
export const GET_MOBILE_TOWERS_ERROR = "GET_MOBILE_TOWERS_ERROR";

export const GET_INACTIVE_MOBILE_TOWERS_STARTING =
  "GET_INACTIVE_MOBILE_TOWERS_STARTING";
export const GET_INACTIVE_MOBILE_TOWERS_SUCCESS =
  "GET_INACTIVE_MOBILE_TOWERS_SUCCESS";
export const GET_INACTIVE_MOBILE_TOWERS_ERROR =
  "GET_INACTIVE_MOBILE_TOWERS_ERROR";

export const ADD_MOBILE_TOWER_STARTING = "ADD_MOBILE_TOWER_STARTING";
export const ADD_MOBILE_TOWER_SUCCESS = "ADD_MOBILE_TOWER_SUCCESS";
export const ADD_MOBILE_TOWER_ERROR = "ADD_MOBILE_TOWER_ERROR";

export const UPDATE_MOBILE_TOWER_STARTING = "UPDATE_MOBILE_TOWER_STARTING";
export const UPDATE_MOBILE_TOWER_SUCCESS = "UPDATE_MOBILE_TOWER_SUCCESS";
export const UPDATE_MOBILE_TOWER_ERROR = "UPDATE_MOBILE_TOWER_ERROR";

export const DEACTIVATE_MOBILE_TOWER_STARTING =
  "DEACTIVATE_MOBILE_TOWER_STARTING";
export const DEACTIVATE_MOBILE_TOWER_SUCCESS =
  "DEACTIVATE_MOBILE_TOWER_SUCCESS";
export const DEACTIVATE_MOBILE_TOWER_ERROR = "DEACTIVATE_MOBILE_TOWER_ERROR";

export const ACTIVATE_MOBILE_TOWER_STARTING = "ACTIVATE_MOBILE_TOWER_STARTING";
export const ACTIVATE_MOBILE_TOWER_SUCCESS = "ACTIVATE_MOBILE_TOWER_SUCCESS";
export const ACTIVATE_MOBILE_TOWER_ERROR = "ACTIVATE_MOBILE_TOWER_ERROR";

export const CLEAR_DATA = "CLEAR_DATA";

export const GET_SEARCH_RESULT_STARTING = "GET_SEARCH_RESULT_STARTING";
export const GET_SEARCH_RESULT_SUCCESS = "GET_SEARCH_RESULT_SUCCESS";
export const GET_SEARCH_RESULT_ERROR = "GET_SEARCH_RESULT_ERROR";

export const GET_SEARCH_SUGGESTIONS_STARTING =
  "GET_SEARCH_SUGGESTIONS_STARTING";
export const GET_SEARCH_SUGGESTIONS_SUCCESS = "GET_SEARCH_SUGGESTIONS_SUCCESS";
export const GET_SEARCH_SUGGESTIONS_ERROR = "GET_SEARCH_SUGGESTIONS_ERROR";

export const SAVE_SEARCH_RESULT_STARTING = "SAVE_SEARCH_RESULT_STARTING";
export const SAVE_SEARCH_RESULT_SUCCESS = "SAVE_SEARCH_RESULT_SUCCESS";
export const SAVE_SEARCH_RESULT_ERROR = "SAVE_SEARCH_RESULT_ERROR";

export const GET_SAVED_REPORTS_STARTING = "GET_SAVED_REPORTS_STARTING";
export const GET_SAVED_REPORTS_SUCCESS = "GET_SAVED_REPORTS_SUCCESS";
export const GET_SAVED_REPORTS_ERROR = "GET_SAVED_REPORTS_ERROR";

export const REVIEW_REPORT_STARTING = "REVIEW_REPORT_STARTING";
export const REVIEW_REPORT_SUCCESS = "REVIEW_REPORT_SUCCESS";
export const REVIEW_REPORT_ERROR = "REVIEW_REPORT_ERROR";

export const APPROVE_REPORT_STARTING = "APPROVE_REPORT_STARTING";
export const APPROVE_REPORT_SUCCESS = "APPROVE_REPORT_SUCCESS";
export const APPROVE_REPORT_ERROR = "APPROVE_REPORT_ERROR";

export const PRINT_REPORT_STARTING = "PRINT_REPORT_STARTING";
export const PRINT_REPORT_SUCCESS = "PRINT_REPORT_SUCCESS";
export const PRINT_REPORT_ERROR = "PRINT_REPORT_ERROR";

export const SAVE_REPORT_COMMENTS_STARTING = "SAVE_REPORT_COMMENTS_STARTING";
export const SAVE_REPORT_COMMENTS_SUCCESS = "SAVE_REPORT_COMMENTS_SUCCESS";
export const SAVE_REPORT_COMMENTS_ERROR = "SAVE_REPORT_COMMENTS_ERROR";

export const GET_DEVICE_DATA_REPORT_STARTING =
  "GET_DEVICE_DATA_REPORT_STARTING";
export const GET_DEVICE_DATA_REPORT_SUCCESS = "GET_DEVICE_DATA_REPORT_SUCCESS";
export const GET_DEVICE_DATA_REPORT_ERROR = "GET_DEVICE_DATA_REPORT_ERROR";

export const GET_MODULES_STARTING = "GET_MODULES_STARTING";
export const GET_MODULES_SUCCESS = "GET_MODULES_SUCCESS";
export const GET_MODULES_ERROR = "GET_MODULES_ERROR";

export const GET_USER_LOCATIONS_STARTING = "GET_USER_LOCATIONS_STARTING";
export const GET_USER_LOCATIONS_SUCCESS = "GET_USER_LOCATIONS_SUCCESS";
export const GET_USER_LOCATIONS_ERROR = "GET_USER_LOCATIONS_ERROR";

export const ADD_DEFECT_STARTING = "ADD_DEFECT_STARTING";
export const ADD_DEFECT_SUCCESS = "ADD_DEFECT_SUCCESS";
export const ADD_DEFECT_ERROR = "ADD_DEFECT_ERROR";

export const UPDATE_DEFECT_STARTING = "UPDATE_DEFECT_STARTING";
export const UPDATE_DEFECT_SUCCESS = "UPDATE_DEFECT_SUCCESS";
export const UPDATE_DEFECT_ERROR = "UPDATE_DEFECT_ERROR";

export const UPDATE_DEFECTS_SUCCESS = "UPDATE_DEFECTS_SUCCESS";

export const ACTIVATE_DEFECT_STARTING = "ACTIVATE_DEFECT_STARTING";
export const ACTIVATE_DEFECT_SUCCESS = "ACTIVATE_DEFECT_SUCCESS";
export const ACTIVATE_DEFECT_ERROR = "ACTIVATE_DEFECT_ERROR";

export const DEACTIVATE_DEFECT_STARTING = "DEACTIVATE_DEFECT_STARTING";
export const DEACTIVATE_DEFECT_SUCCESS = "DEACTIVATE_DEFECT_SUCCESS";
export const DEACTIVATE_DEFECT_ERROR = "DEACTIVATE_DEFECT_ERROR";

export const ADD_TENANT_STARTING = "ADD_TENANT_STARTING";
export const ADD_TENANT_SUCCESS = "ADD_TENANT_SUCCESS";
export const ADD_TENANT_ERROR = "ADD_TENANT_ERROR";

export const UPDATE_TENANT_STARTING = "UPDATE_TENANT_STARTING";
export const UPDATE_TENANT_SUCCESS = "UPDATE_TENANT_SUCCESS";
export const UPDATE_TENANT_ERROR = "UPDATE_TENANT_ERROR";

export const ACTIVATE_TENANT_STARTING = "ACTIVATE_TENANT_STARTING";
export const ACTIVATE_TENANT_SUCCESS = "ACTIVATE_TENANT_SUCCESS";
export const ACTIVATE_TENANT_ERROR = "ACTIVATE_TENANT_ERROR";

export const DEACTIVATE_TENANT_STARTING = "DEACTIVATE_TENANT_STARTING";
export const DEACTIVATE_TENANT_SUCCESS = "DEACTIVATE_TENANT_SUCCESS";
export const DEACTIVATE_TENANT_ERROR = "DEACTIVATE_TENANT_ERROR";

export const GET_MODELS_STARTING = "GET_MODELS_STARTING";
export const GET_MODELS_SUCCESS = "GET_MODELS_SUCCESS";
export const GET_MODELS_ERROR = "GET_MODELS_ERROR";

export const GET_WEATHER_DETAILS_STARTING = "GET_WEATHER_DETAILS_STARTING";
export const GET_WEATHER_DETAILS_SUCCESS = "GET_WEATHER_DETAILS_SUCCESS";
export const GET_WEATHER_DETAILS_ERROR = "GET_WEATHER_DETAILS_ERROR";

export const FORGOT_PASSWORD_STARTING = "FORGOT_PASSWORD_STARTING";
export const FORGOT_PASSWORD_SUCCESS = "FORGOT_PASSWORD_SUCCESS";
export const FORGOT_PASSWORD_ERROR = "FORGOT_PASSWORD_ERROR";

export const GET_DEFECTS_STARTING = "GET_DEFECTS_STARTING";
export const GET_DEFECTS_SUCCESS = "GET_DEFECTS_SUCCESS";
export const GET_DEFECTS_ERROR = "GET_DEFECTS_ERROR";

export const GET_TENANTS_STARTING = "GET_TENANTS_STARTING";
export const GET_TENANTS_SUCCESS = "GET_TENANTS_SUCCESS";
export const GET_TENANTS_ERROR = "GET_TENANTS_ERROR";

export const GET_REGIONS_STARTING = "GET_REGIONS_STARTING";
export const GET_REGIONS_SUCCESS = "GET_REGIONS_SUCCESS";
export const GET_REGIONS_ERROR = "GET_REGIONS_ERROR";

export const GET_REGION_TOWERS_STARTING = "GET_REGION_TOWERS_STARTING";
export const GET_REGION_TOWERS_SUCCESS = "GET_REGION_TOWERS_SUCCESS";
export const GET_REGION_TOWERS_ERROR = "GET_REGION_TOWERS_ERROR";

export const REGIONS_COMPANY_CHANGED = "REGIONS_COMPANY_CHANGED";
export const REGION_TREE_STATE_CHANGED = "REGION_TREE_STATE_CHANGED";

export const GET_TOWERS_SITENAME_STARTING = "GET_TOWERS_SITENAME_STARTING";
export const GET_TOWERS_SITENAME_SUCCESS = "GET_TOWERS_SITENAME_SUCCESS";
export const GET_TOWERS_SITENAME_ERROR = "GET_TOWERS_SITENAME_ERROR";

export const ADD_TOWER_SITENAME_STARTING = "ADD_TOWER_SITENAME_STARTING";
export const ADD_TOWER_SITENAME_SUCCESS = "ADD_TOWER_SITENAME_SUCCESS";
export const ADD_TOWER_SITENAME_ERROR = "ADD_TOWER_SITENAME_ERROR";

//places
export const GET_PLACES_STARTING = "GET_PLACES_STARTING";
export const GET_PLACES_SUCCESS = "GET_PLACES_SUCCESS";
export const GET_PLACES_ERROR = "GET_PLACES_ERROR";

export const GET_INACTIVE_PLACES_STARTING = "GET_INACTIVE_PLACES_STARTING";
export const GET_INACTIVE_PLACES_SUCCESS = "GET_INACTIVE_PLACES_SUCCESS";
export const GET_INACTIVE_PLACES_ERROR = "GET_INACTIVE_PLACES_ERROR";

export const GET_CATEGORY_PLACES_STARTING = "GET_CATEGORY_PLACES_STARTING";
export const GET_CATEGORY_PLACES_SUCCESS = "GET_CATEGORY_PLACES_SUCCESS";
export const GET_CATEGORY_PLACES_ERROR = "GET_CATEGORY_PLACES_ERROR";

export const GET_PLACE_BY_ID_STARTING = "GET_PLACE_BY_ID_STARTING";
export const GET_PLACE_BY_ID_SUCCESS = "GET_PLACE_BY_ID_SUCCESS";
export const GET_PLACE_BY_ID_ERROR = "GET_PLACE_BY_ID_ERROR";

export const ADD_PLACE_STARTING = "ADD_PLACE_STARTING";
export const ADD_PLACE_SUCCESS = "ADD_PLACE_SUCCESS";
export const ADD_PLACE_ERROR = "ADD_PLACE_ERROR";

export const UPDATE_PLACE_STARTING = "UPDATE_PLACE_STARTING";
export const UPDATE_PLACE_SUCCESS = "UPDATE_PLACE_SUCCESS";
export const UPDATE_PLACE_ERROR = "UPDATE_PLACE_ERROR";

export const ACTIVATE_PLACE_STARTING = "ACTIVATE_PLACE_STARTING";
export const ACTIVATE_PLACE_SUCCESS = "ACTIVATE_PLACE_SUCCESS";
export const ACTIVATE_PLACE_ERROR = "ACTIVATE_PLACE_ERROR";

export const DEACTIVATE_PLACE_STARTING = "DEACTIVATE_PLACE_STARTING";
export const DEACTIVATE_PLACE_SUCCESS = "DEACTIVATE_PLACE_SUCCESS";
export const DEACTIVATE_PLACE_ERROR = "DEACTIVATE_PLACE_ERROR";

export const GET_RECOMMENDATIONS_STARTING = "GET_RECOMMENDATIONS_STARTING";
export const GET_RECOMMENDATIONS_SUCCESS = "GET_RECOMMENDATIONS_SUCCESS";
export const GET_RECOMMENDATIONS_ERROR = "GET_RECOMMENDATIONS_ERROR";

export const ADD_RECOMMENDATION_STARTING = "ADD_RECOMMENDATION_STARTING";
export const ADD_RECOMMENDATION_SUCCESS = "ADD_RECOMMENDATION_SUCCESS";
export const ADD_RECOMMENDATION_ERROR = "ADD_RECOMMENDATION_ERROR";

export const UPDATE_RECOMMENDATION_STARTING = "UPDATE_RECOMMENDATION_STARTING";
export const UPDATE_RECOMMENDATION_SUCCESS = "UPDATE_RECOMMENDATION_SUCCESS";
export const UPDATE_RECOMMENDATION_ERROR = "UPDATE_RECOMMENDATION_ERROR";

export const ADD_MOBILE_TOWER_IMAGE_STARTING =
  "ADD_MOBILE_TOWER_IMAGE_STARTING";
export const ADD_MOBILE_TOWER_IMAGE_SUCCESS = "ADD_MOBILE_TOWER_IMAGE_SUCCESS";
export const ADD_MOBILE_TOWER_IMAGE_ERROR = "ADD_MOBILE_TOWER_IMAGE_ERROR";

export const DELETE_MOBILE_TOWER_IMAGE_STARTING =
  "DELETE_MOBILE_TOWER_IMAGE_STARTING";
export const DELETE_MOBILE_TOWER_IMAGE_SUCCESS =
  "DELETE_MOBILE_TOWER_IMAGE_SUCCESS";
export const DELETE_MOBILE_TOWER_IMAGE_ERROR =
  "DELETE_MOBILE_TOWER_IMAGE_ERROR";

export const GET_INACTIVE_RECOMMENDATIONS_STARTING =
  "GET_INACTIVE_RECOMMENDATIONS_STARTING";
export const GET_INACTIVE_RECOMMENDATIONS_SUCCESS =
  "GET_INACTIVE_RECOMMENDATIONS_SUCCESS";
export const GET_INACTIVE_RECOMMENDATIONS_ERROR =
  "GET_INACTIVE_RECOMMENDATIONS_ERROR";

export const ACTIVATE_RECOMMENDATION_STARTING =
  "ACTIVATE_RECOMMENDATION_STARTING";
export const ACTIVATE_RECOMMENDATION_SUCCESS =
  "ACTIVATE_RECOMMENDATION_SUCCESS";
export const ACTIVATE_RECOMMENDATION_ERROR = "ACTIVATE_RECOMMENDATION_ERROR";

export const DEACTIVATE_RECOMMENDATION_STARTING =
  "DEACTIVATE_RECOMMENDATION_STARTING";
export const DEACTIVATE_RECOMMENDATION_SUCCESS =
  "DEACTIVATE_RECOMMENDATION_SUCCESS";
export const DEACTIVATE_RECOMMENDATION_ERROR =
  "DEACTIVATE_RECOMMENDATION_ERROR";

export const ADD_IMAGE_LABEL_STARTING = "ADD_IMAGE_LABEL_STARTING";
export const ADD_IMAGE_LABEL_SUCCESS = "ADD_IMAGE_LABEL_SUCCESS";
export const ADD_IMAGE_LABEL_ERROR = "ADD_IMAGE_LABEL_ERROR";

export const ADD_TOWER_CHECKLIST_STARTING = "ADD_TOWER_CHECKLIST_STARTING";
export const ADD_TOWER_CHECKLIST_SUCCESS = "ADD_TOWER_CHECKLIST_SUCCESS";
export const ADD_TOWER_CHECKLIST_ERROR = "ADD_TOWER_CHECKLIST_ERROR";

export const GET_MEMBERS_STARTING = "GET_MEMBERS_STARTING";
export const GET_MEMBERS_SUCCESS = "GET_MEMBERS_SUCCESS";
export const GET_MEMBERS_ERROR = "GET_MEMBERS_ERROR";

export const ADD_MEMBER_STARTING = "ADD_MEMBER_STARTING";
export const ADD_MEMBER_SUCCESS = "ADD_MEMBER_SUCCESS";
export const ADD_MEMBER_ERROR = "ADD_MEMBER_ERROR";

export const UPDATE_MEMBER_STARTING = "UPDATE_MEMBER_STARTING";
export const UPDATE_MEMBER_SUCCESS = "UPDATE_MEMBER_SUCCESS";
export const UPDATE_MEMBER_ERROR = "UPDATE_MEMBER_ERROR";

export const ACTIVATE_MEMBER_STARTING = "ACTIVATE_MEMBER_STARTING";
export const ACTIVATE_MEMBER_SUCCESS = "ACTIVATE_MEMBER_SUCCESS";
export const ACTIVATE_MEMBER_ERROR = "ACTIVATE_MEMBER_ERROR";

export const DEACTIVATE_MEMBER_STARTING = "DEACTIVATE_MEMBER_STARTING";
export const DEACTIVATE_MEMBER_SUCCESS = "DEACTIVATE_MEMBER_SUCCESS";
export const DEACTIVATE_MEMBER_ERROR = "DEACTIVATE_MEMBER_ERROR";

export const ADD_GUSSET_END_PLATE_STARTING = "ADD_GUSSET_END_PLATE_STARTING";
export const ADD_GUSSET_END_PLATE_SUCCESS = "ADD_GUSSET_END_PLATE_SUCCESS";
export const ADD_GUSSET_END_PLATE_ERROR = "ADD_GUSSET_END_PLATE_ERROR";

export const UPDATE_GUSSET_END_PLATE_STARTING =
  "UPDATE_GUSSET_END_PLATE_STARTING";
export const UPDATE_GUSSET_END_PLATE_SUCCESS =
  "UPDATE_GUSSET_END_PLATE_SUCCESS";
export const UPDATE_GUSSET_END_PLATE_ERROR = "UPDATE_GUSSET_END_PLATE_ERROR";

export const ACTIVATE_GUSSET_END_PLATE_STARTING =
  "ACTIVATE_GUSSET_END_PLATE_STARTING";
export const ACTIVATE_GUSSET_END_PLATE_SUCCESS =
  "ACTIVATE_GUSSET_END_PLATE_SUCCESS";
export const ACTIVATE_GUSSET_END_PLATE_ERROR =
  "ACTIVATE_GUSSET_END_PLATE_ERROR";

export const DEACTIVATE_GUSSET_END_PLATE_STARTING =
  "DEACTIVATE_GUSSET_END_PLATE_STARTING";
export const DEACTIVATE_GUSSET_END_PLATE_SUCCESS =
  "DEACTIVATE_GUSSET_END_PLATE_SUCCESS";
export const DEACTIVATE_GUSSET_END_PLATE_ERROR =
  "DEACTIVATE_GUSSET_END_PLATE_ERROR";

export const GET_GUSSET_END_PLATES_STARTING = "GET_GUSSET_END_PLATES_STARTING";
export const GET_GUSSET_END_PLATES_SUCCESS = "GET_GUSSET_END_PLATES_SUCCESS";
export const GET_GUSSET_END_PLATES_ERROR = "GET_GUSSET_END_PLATES_ERROR";

export const ADD_BUTT_JOINT_CONNECTION_STARTING =
  "ADD_BUTT_JOINT_CONNECTION_STARTING";
export const ADD_BUTT_JOINT_CONNECTION_SUCCESS =
  "ADD_BUTT_JOINT_CONNECTION_SUCCESS";
export const ADD_BUTT_JOINT_CONNECTION_ERROR =
  "ADD_BUTT_JOINT_CONNECTION_ERROR";

export const UPDATE_BUTT_JOINT_CONNECTION_STARTING =
  "UPDATE_BUTT_JOINT_CONNECTION_STARTING";
export const UPDATE_BUTT_JOINT_CONNECTION_SUCCESS =
  "UPDATE_BUTT_JOINT_CONNECTION_SUCCESS";
export const UPDATE_BUTT_JOINT_CONNECTION_ERROR =
  "UPDATE_BUTT_JOINT_CONNECTION_ERROR";

export const ACTIVATE_BUTT_JOINT_CONNECTION_STARTING =
  "ACTIVATE_BUTT_JOINT_CONNECTION_STARTING";
export const ACTIVATE_BUTT_JOINT_CONNECTION_SUCCESS =
  "ACTIVATE_BUTT_JOINT_CONNECTION_SUCCESS";
export const ACTIVATE_BUTT_JOINT_CONNECTION_ERROR =
  "ACTIVATE_BUTT_JOINT_CONNECTION_ERROR";

export const DEACTIVATE_BUTT_JOINT_CONNECTION_STARTING =
  "DEACTIVATE_BUTT_JOINT_CONNECTION_STARTING";
export const DEACTIVATE_BUTT_JOINT_CONNECTION_SUCCESS =
  "DEACTIVATE_BUTT_JOINT_CONNECTION_SUCCESS";
export const DEACTIVATE_BUTT_JOINT_CONNECTION_ERROR =
  "DEACTIVATE_BUTT_JOINT_CONNECTION_ERROR";

export const GET_BUTT_JOINT_CONNECTIONS_STARTING =
  "GET_BUTT_JOINT_CONNECTIONS_STARTING";
export const GET_BUTT_JOINT_CONNECTIONS_SUCCESS =
  "GET_BUTT_JOINT_CONNECTIONS_SUCCESS";
export const GET_BUTT_JOINT_CONNECTIONS_ERROR =
  "GET_BUTT_JOINT_CONNECTIONS_ERROR";

export const GET_FLANGE_JOINT_CONNECTIONS_STARTING =
  "GET_FLANGE_JOINT_CONNECTIONS_STARTING";
export const GET_FLANGE_JOINT_CONNECTIONS_SUCCESS =
  "GET_FLANGE_JOINT_CONNECTIONS_SUCCESS";
export const GET_FLANGE_JOINT_CONNECTIONS_ERROR =
  "GET_FLANGE_JOINT_CONNECTIONS_ERROR";

export const ADD_FLANGE_JOINT_CONNECTION_STARTING =
  "ADD_FLANGE_JOINT_CONNECTION_STARTING";
export const ADD_FLANGE_JOINT_CONNECTION_SUCCESS =
  "ADD_FLANGE_JOINT_CONNECTION_SUCCESS";
export const ADD_FLANGE_JOINT_CONNECTION_ERROR =
  "ADD_FLANGE_JOINT_CONNECTION_ERROR";

export const UPDATE_FLANGE_JOINT_CONNECTION_STARTING =
  "UPDATE_FLANGE_JOINT_CONNECTION_STARTING";
export const UPDATE_FLANGE_JOINT_CONNECTION_SUCCESS =
  "UPDATE_FLANGE_JOINT_CONNECTION_SUCCESS";
export const UPDATE_FLANGE_JOINT_CONNECTION_ERROR =
  "UPDATE_FLANGE_JOINT_CONNECTION_ERROR";

export const ACTIVATE_FLANGE_JOINT_CONNECTION_STARTING =
  "ACTIVATE_FLANGE_JOINT_CONNECTION_STARTING";
export const ACTIVATE_FLANGE_JOINT_CONNECTION_SUCCESS =
  "ACTIVATE_FLANGE_JOINT_CONNECTION_SUCCESS";
export const ACTIVATE_FLANGE_JOINT_CONNECTION_ERROR =
  "ACTIVATE_FLANGE_JOINT_CONNECTION_ERROR";

export const DEACTIVATE_FLANGE_JOINT_CONNECTION_STARTING =
  "DEACTIVATE_FLANGE_JOINT_CONNECTION_STARTING";
export const DEACTIVATE_FLANGE_JOINT_CONNECTION_SUCCESS =
  "DEACTIVATE_FLANGE_JOINT_CONNECTION_SUCCESS";
export const DEACTIVATE_FLANGE_JOINT_CONNECTION_ERROR =
  "DEACTIVATE_FLANGE_JOINT_CONNECTION_ERROR";

export const ADD_TOWER_SITEREPORT_STARTING = "ADD_TOWER_SITEREPORT_STARTING";
export const ADD_TOWER_SITEREPORT_SUCCESS = "ADD_TOWER_SITEREPORT_SUCCESS";
export const ADD_TOWER_SITEREPORT_ERROR = "ADD_TOWER_SITEREPORT_ERROR";

export const GET_ENTITIES_ACCESS_STARTING = "GET_ENTITIES_ACCESS_STARTING";
export const GET_ENTITIES_ACCESS_SUCCESS = "GET_ENTITIES_ACCESS_SUCCESS";
export const GET_ENTITIES_ACCESS_ERROR = "GET_ENTITIES_ACCESS_ERROR";

export const ADD_ENTITY_ACCESS_STARTING = "ADD_ENTITY_ACCESS_STARTING";
export const ADD_ENTITY_ACCESS_SUCCESS = "ADD_ENTITY_ACCESS_SUCCESS";
export const ADD_ENTITY_ACCESS_ERROR = "ADD_ENTITY_ACCESS_ERROR";

export const DELETE_ENTITY_ACCESS_STARTING = "DELETE_ENTITY_ACCESS_STARTING";
export const DELETE_ENTITY_ACCESS_SUCCESS = "DELETE_ENTITY_ACCESS_SUCCESS";
export const DELETE_ENTITY_ACCESS_ERROR = "DELETE_ENTITY_ACCESS_ERROR";

export const ADD_CLIENT_LOGO_STARTING = "ADD_CLIENT_LOGO_STARTING";
export const ADD_CLIENT_LOGO_SUCCESS = "ADD_CLIENT_LOGO_SUCCESS";
export const ADD_CLIENT_LOGO_ERROR = "ADD_CLIENT_LOGO_ERROR";

export const GET_TOWER_DATA_STARTING = "GET_TOWER_DATA_STARTING";
export const GET_TOWER_DATA_SUCCESS = "GET_TOWER_DATA_SUCCESS";
export const GET_TOWER_DATA_ERROR = "GET_TOWER_DATA_ERROR";

export const GET_COMPANIES_STARTING = "GET_COMPANIES_STARTING";
export const GET_COMPANIES_SUCCESS = "GET_COMPANIES_SUCCESS";
export const GET_COMPANIES_ERROR = "GET_COMPANIES_ERROR";

export const GET_COMPANY_BY_ID_STARTING = "GET_COMPANY_BY_ID_STARTING";
export const GET_COMPANY_BY_ID_SUCCESS = "GET_COMPANY_BY_ID_SUCCESS";
export const GET_COMPANY_BY_ID_ERROR = "GET_COMPANY_BY_ID_ERROR";

export const ADD_COMPANY_STARTING = "ADD_COMPANY_STARTING";
export const ADD_COMPANY_SUCCESS = "ADD_COMPANY_SUCCESS";
export const ADD_COMPANY_ERROR = "ADD_COMPANY_ERROR";

export const UPDATE_COMPANY_STARTING = "UPDATE_COMPANY_STARTING";
export const UPDATE_COMPANY_SUCCESS = "UPDATE_COMPANY_SUCCESS";
export const UPDATE_COMPANY_ERROR = "UPDATE_COMPANY_ERROR";

export const ACTIVATE_COMPANY_STARTING = "ACTIVATE_COMPANY_STARTING";
export const ACTIVATE_COMPANY_SUCCESS = "ACTIVATE_COMPANY_SUCCESS";
export const ACTIVATE_COMPANY_ERROR = "ACTIVATE_COMPANY_ERROR";

export const DEACTIVATE_COMPANY_STARTING = "DEACTIVATE_COMPANY_STARTING";
export const DEACTIVATE_COMPANY_SUCCESS = "DEACTIVATE_COMPANY_SUCCESS";
export const DEACTIVATE_COMPANY_ERROR = "DEACTIVATE_COMPANY_ERROR";

export const GET_INACTIVE_COMPANIES_STARTING =
  "GET_INACTIVE_COMPANIES_STARTING";
export const GET_INACTIVE_COMPANIES_SUCCESS = "GET_INACTIVE_COMPANIES_SUCCESS";
export const GET_INACTIVE_COMPANIES_ERROR = "GET_INACTIVE_COMPANIES_ERROR";

export const GET_PANEL_DETAILS_STARTING = "GET_PANEL_DETAILS_STARTING";
export const GET_PANEL_DETAILS_SUCCESS = "GET_PANEL_DETAILS_SUCCESS";
export const GET_PANEL_DETAILS_ERROR = "GET_PANEL_DETAILS_ERROR";

export const ADD_PANEL_DETAILS_STARTING = "ADD_PANEL_DETAILS_STARTING";
export const ADD_PANEL_DETAILS_SUCCESS = "ADD_PANEL_DETAILS_SUCCESS";
export const ADD_PANEL_DETAILS_ERROR = "ADD_PANEL_DETAILS_ERROR";

export const UPDATE_PANEL_DETAILS_STARTING = "UPDATE_PANEL_DETAILS_STARTING";
export const UPDATE_PANEL_DETAILS_SUCCESS = "UPDATE_PANEL_DETAILS_SUCCESS";
export const UPDATE_PANEL_DETAILS_ERROR = "UPDATE_PANEL_DETAILS_ERROR";

export const DEACTIVATE_PANEL_DETAILS_STARTING =
  "DEACTIVATE_PANEL_DETAILS_STARTING";
export const DEACTIVATE_PANEL_DETAILS_SUCCESS =
  "DEACTIVATE_PANEL_DETAILS_SUCCESS";
export const DEACTIVATE_PANEL_DETAILS_ERROR = "DEACTIVATE_PANEL_DETAILS_ERROR";

export const GET_ANALYSIS_STARTING = "GET_ANALYSIS_STARTING";
export const GET_ANALYSIS_SUCCESS = "GET_ANALYSIS_SUCCESS";
export const GET_ANALYSIS_ERROR = "GET_ANALYSIS_ERROR";

export const GET_ANALYSIS_RTP_STARTING = "GET_ANALYSIS_RTP_STARTING";
export const GET_ANALYSIS_RTP_SUCCESS = "GET_ANALYSIS_RTP_SUCCESS";
export const GET_ANALYSIS_RTP_ERROR = "GET_ANALYSIS_RTP_ERROR";

export const GET_TOWERS_COMPANY_STARTING = "GET_TOWERS_COMPANY_STARTING";
export const GET_TOWERS_COMPANY_SUCCESS = "GET_TOWERS_COMPANY_SUCCESS";
export const GET_TOWERS_COMPANY_ERROR = "GET_TOWERS_COMPANY_ERROR";

export const ADD_TOWER_COMPANY_STARTING = "ADD_TOWER_COMPANY_STARTING";
export const ADD_TOWER_COMPANY_SUCCESS = "ADD_TOWER_COMPANY_SUCCESS";
export const ADD_TOWER_COMPANY_ERROR = "ADD_TOWER_COMPANY_ERROR";

export const DELETE_TOWER_COMPANY_STARTING = "DELETE_TOWER_COMPANY_STARTING";
export const DELETE_TOWER_COMPANY_SUCCESS = "DELETE_TOWER_COMPANY_SUCCESS";
export const DELETE_TOWER_COMPANY_ERROR = "DELETE_TOWER_COMPANY_ERROR";

export const GET_MATERIAL_SPECIFICATIONS_STARTING =
  "GET_MATERIAL_SPECIFICATIONS_STARTING";
export const GET_MATERIAL_SPECIFICATIONS_SUCCESS =
  "GET_MATERIAL_SPECIFICATIONS_SUCCESS";
export const GET_MATERIAL_SPECIFICATIONS_ERROR =
  "GET_MATERIAL_SPECIFICATIONS_ERROR";

export const UPDATE_MATERIAL_SPECIFICATION_STARTING =
  "UPDATE_MATERIAL_SPECIFICATION_STARTING";
export const UPDATE_MATERIAL_SPECIFICATION_SUCCESS =
  "UPDATE_MATERIAL_SPECIFICATION_SUCCESS";
export const UPDATE_MATERIAL_SPECIFICATION_ERROR =
  "UPDATE_MATERIAL_SPECIFICATION_ERROR";

export const GET_USER_PREFERENCES_STARTING = "GET_USER_PREFERENCES_STARTING";
export const GET_USER_PREFERENCES_SUCCESS = "GET_USER_PREFERENCES_SUCCESS";
export const GET_USER_PREFERENCES_ERROR = "GET_USER_PREFERENCES_ERROR";

export const UPDATE_USER_PREFERENCE_STARTING =
  "UPDATE_USER_PREFERENCE_STARTING";
export const UPDATE_USER_PREFERENCE_SUCCESS = "UPDATE_USER_PREFERENCE_SUCCESS";
export const UPDATE_USER_PREFERENCE_ERROR = "UPDATE_USER_PREFERENCE_ERROR";

export const ADD_TOWER_DRAWING_STARTING = "ADD_TOWER_DRAWING_STARTING";
export const ADD_TOWER_DRAWING_SUCCESS = "ADD_TOWER_DRAWING_SUCCESS";
export const ADD_TOWER_DRAWING_ERROR = "ADD_TOWER_DRAWING_ERROR";

export const GET_AUDIT_DATA_STARTING = "GET_AUDIT_DATA_STARTING";
export const GET_AUDIT_DATA_SUCCESS = "GET_AUDIT_DATA_SUCCESS";
export const GET_AUDIT_DATA_ERROR = "GET_AUDIT_DATA_ERROR";

export const ADD_AUDIT_DATA_STARTING = "ADD_AUDIT_DATA_STARTING";
export const ADD_AUDIT_DATA_SUCCESS = "ADD_AUDIT_DATA_SUCCESS";
export const ADD_AUDIT_DATA_ERROR = "ADD_AUDIT_DATA_ERROR";

export const UPDATE_AUDIT_DATA_STARTING = "UPDATE_AUDIT_DATA_STARTING";
export const UPDATE_AUDIT_DATA_SUCCESS = "UPDATE_AUDIT_DATA_SUCCESS";
export const UPDATE_AUDIT_DATA_ERROR = "UPDATE_AUDIT_DATA_ERROR";

export const DEACTIVATE_AUDIT_DATA_STARTING = "DEACTIVATE_AUDIT_DATA_STARTING";
export const DEACTIVATE_AUDIT_DATA_SUCCESS = "DEACTIVATE_AUDIT_DATA_SUCCESS";
export const DEACTIVATE_AUDIT_DATA_ERROR = "DEACTIVATE_AUDIT_DATA_ERROR";

export const GET_COUNTRIES_STARTING = "GET_COUNTRIES_STARTING";
export const GET_COUNTRIES_SUCCESS = "GET_COUNTRIES_SUCCESS";
export const GET_COUNTRIES_ERROR = "GET_COUNTRIES_ERROR";

export const GET_MOBILE_TOWER_DESIGN_STARTING =
  "GET_MOBILE_TOWER_DESIGN_STARTING";
export const GET_MOBILE_TOWER_DESIGN_SUCCESS =
  "GET_MOBILE_TOWER_DESIGN_SUCCESS";
export const GET_MOBILE_TOWER_DESIGN_ERROR = "GET_MOBILE_TOWER_DESIGN_ERROR";

export const GET_MOBILE_TOWER_DESIGN_BY_ID_STARTING =
  "GET_MOBILE_TOWER_DESIGN_BY_ID_STARTING";
export const GET_MOBILE_TOWER_DESIGN_BY_ID_SUCCESS =
  "GET_MOBILE_TOWER_DESIGN_BY_ID_SUCCESS";
export const GET_MOBILE_TOWER_DESIGN_BY_ID_ERROR =
  "GET_MOBILE_TOWER_DESIGN_BY_ID_ERROR";

export const ADD_MOBILE_TOWER_DESIGN_STARTING =
  "ADD_MOBILE_TOWER_DESIGN_STARTING";
export const ADD_MOBILE_TOWER_DESIGN_SUCCESS =
  "ADD_MOBILE_TOWER_DESIGN_SUCCESS";
export const ADD_MOBILE_TOWER_DESIGN_ERROR = "ADD_MOBILE_TOWER_DESIGN_ERROR";

export const UPDATE_MOBILE_TOWER_DESIGN_STARTING =
  "UPDATE_MOBILE_TOWER_DESIGN_STARTING";
export const UPDATE_MOBILE_TOWER_DESIGN_SUCCESS =
  "UPDATE_MOBILE_TOWER_DESIGN_SUCCESS";
export const UPDATE_MOBILE_TOWER_DESIGN_ERROR =
  "UPDATE_MOBILE_TOWER_DESIGN_ERROR";

export const ACTIVATE_MOBILE_TOWER_DESIGN_STARTING =
  "ACTIVATE_MOBILE_TOWER_DESIGN_STARTING";
export const ACTIVATE_MOBILE_TOWER_DESIGN_SUCCESS =
  "ACTIVATE_MOBILE_TOWER_DESIGN_SUCCESS";
export const ACTIVATE_MOBILE_TOWER_DESIGN_ERROR =
  "ACTIVATE_MOBILE_TOWER_DESIGN_ERROR";

export const DEACTIVATE_MOBILE_TOWER_DESIGN_STARTING =
  "DEACTIVATE_MOBILE_TOWER_DESIGN_STARTING";
export const DEACTIVATE_MOBILE_TOWER_DESIGN_SUCCESS =
  "DEACTIVATE_MOBILE_TOWER_DESIGN_SUCCESS";
export const DEACTIVATE_MOBILE_TOWER_DESIGN_ERROR =
  "DEACTIVATE_MOBILE_TOWER_DESIGN_ERROR";

export const GET_INACTIVE_MOBILE_TOWER_DESIGNS_STARTING =
  "GET_INACTIVE_MOBILE_TOWER_DESIGN_STARTING";
export const GET_INACTIVE_MOBILE_TOWER_DESIGNS_SUCCESS =
  "GET_INACTIVE_MOBILE_TOWER_DESIGN_SUCCESS";
export const GET_INACTIVE_MOBILE_TOWER_DESIGNS_ERROR =
  "GET_INACTIVE_MOBILE_TOWER_DESIGN_ERROR";

export const GET_CALCULATED_FORCE_STARTING = "GET_CALCULATED_FORCE_STARTING";
export const GET_CALCULATED_FORCE_SUCCESS = "GET_CALCULATED_FORCE_SUCCESS";
export const GET_CALCULATED_FORCE_ERROR = "GET_CALCULATED_FORCE_ERROR";

export const ADD_CALCULATED_FORCE_STARTING = "ADD_CALCULATED_FORCE_STARTING";
export const ADD_CALCULATED_FORCE_SUCCESS = "ADD_CALCULATED_FORCE_SUCCESS";
export const ADD_CALCULATED_FORCE_ERROR = "ADD_CALCULATED_FORCE_ERROR";

export const UPDATE_CALCULATED_FORCE_STARTING =
  "UPDATE_CALCULATED_FORCE_STARTING";
export const UPDATE_CALCULATED_FORCE_SUCCESS =
  "UPDATE_CALCULATED_FORCE_SUCCESS";
export const UPDATE_CALCULATED_FORCE_ERROR = "UPDATE_CALCULATED_FORCE_ERROR";

export const DEACTIVATE_CALCULATED_FORCE_STARTING =
  "DEACTIVATE_CALCULATED_FORCE_STARTING";
export const DEACTIVATE_CALCULATED_FORCE_SUCCESS =
  "DEACTIVATE_CALCULATED_FORCE_SUCCESS";
export const DEACTIVATE_CALCULATED_FORCE_ERROR =
  "DEACTIVATE_CALCULATED_FORCE_ERROR";

export const GET_CONNECTION_BOLTS_STARTING = "GET_CONNECTION_BOLTS_STARTING";
export const GET_CONNECTION_BOLTS_SUCCESS = "GET_CONNECTION_BOLTS_SUCCESS";
export const GET_CONNECTION_BOLTS_ERROR = "GET_CONNECTION_BOLTS_ERROR";

export const ADD_CONNECTION_BOLTS_STARTING = "ADD_CONNECTION_BOLTS_STARTING";
export const ADD_CONNECTION_BOLTS_SUCCESS = "ADD_CONNECTION_BOLTS_SUCCESS";
export const ADD_CONNECTION_BOLTS_ERROR = "ADD_CONNECTION_BOLTS_ERROR";

export const UPDATE_CONNECTION_BOLTS_STARTING =
  "UPDATE_CONNECTION_BOLTS_STARTING";
export const UPDATE_CONNECTION_BOLTS_SUCCESS =
  "UPDATE_CONNECTION_BOLTS_SUCCESS";
export const UPDATE_CONNECTION_BOLTS_ERROR = "UPDATE_CONNECTION_BOLTS_ERROR";

export const DEACTIVATE_CONNECTION_BOLTS_STARTING =
  "DEACTIVATE_CONNECTION_BOLTS_STARTING";
export const DEACTIVATE_CONNECTION_BOLTS_SUCCESS =
  "DEACTIVATE_CONNECTION_BOLTS_SUCCESS";
export const DEACTIVATE_CONNECTION_BOLTS_ERROR =
  "DEACTIVATE_CONNECTION_BOLTS_ERROR";

export const GET_POLE_DETAILS_STARTING = "GET_POLE_DETAILS_STARTING";
export const GET_POLE_DETAILS_SUCCESS = "GET_POLE_DETAILS_SUCCESS";
export const GET_POLE_DETAILS_ERROR = "GET_POLE_DETAILS_ERROR";

export const ADD_POLE_DETAILS_STARTING = "ADD_POLE_DETAILS_STARTING";
export const ADD_POLE_DETAILS_SUCCESS = "ADD_POLE_DETAILS_SUCCESS";
export const ADD_POLE_DETAILS_ERROR = "ADD_POLE_DETAILS_ERROR";

export const UPDATE_POLE_DETAILS_STARTING = "UPDATE_POLE_DETAILS_STARTING";
export const UPDATE_POLE_DETAILS_SUCCESS = "UPDATE_POLE_DETAILS_SUCCESS";
export const UPDATE_POLE_DETAILS_ERROR = "UPDATE_POLE_DETAILS_ERROR";

export const DEACTIVATE_POLE_DETAILS_STARTING =
  "DEACTIVATE_POLE_DETAILS_STARTING";
export const DEACTIVATE_POLE_DETAILS_SUCCESS =
  "DEACTIVATE_POLE_DETAILS_SUCCESS";
export const DEACTIVATE_POLE_DETAILS_ERROR = "DEACTIVATE_POLE_DETAILS_ERROR";

export const GET_POLE_MEMBERS_STARTING = "GET_POLE_MEMBERS_STARTING";
export const GET_POLE_MEMBERS_SUCCESS = "GET_POLE_MEMBERS_SUCCESS";
export const GET_POLE_MEMBERS_ERROR = "GET_POLE_MEMBERS_ERROR";

export const ADD_POLE_MEMBER_STARTING = "ADD_POLE_MEMBER_STARTING";
export const ADD_POLE_MEMBER_SUCCESS = "ADD_POLE_MEMBER_SUCCESS";
export const ADD_POLE_MEMBER_ERROR = "ADD_POLE_MEMBER_ERROR";

export const UPDATE_POLE_MEMBER_STARTING = "UPDATE_POLE_MEMBER_STARTING";
export const UPDATE_POLE_MEMBER_SUCCESS = "UPDATE_POLE_MEMBER_SUCCESS";
export const UPDATE_POLE_MEMBER_ERROR = "UPDATE_POLE_MEMBER_ERROR";

export const ACTIVATE_POLE_MEMBER_STARTING = "ACTIVATE_POLE_MEMBER_STARTING";
export const ACTIVATE_POLE_MEMBER_SUCCESS = "ACTIVATE_POLE_MEMBER_SUCCESS";
export const ACTIVATE_POLE_MEMBER_ERROR = "ACTIVATE_POLE_MEMBER_ERROR";

export const DEACTIVATE_POLE_MEMBER_STARTING =
  "DEACTIVATE_POLE_MEMBER_STARTING";
export const DEACTIVATE_POLE_MEMBER_SUCCESS = "DEACTIVATE_POLE_MEMBER_SUCCESS";
export const DEACTIVATE_POLE_MEMBER_ERROR = "DEACTIVATE_POLE_MEMBER_ERROR";

export const GET_FOUNDATION_FORCE_POLE_DETAILS_STARTING =
  "GET_FOUNDATION_POLE_DETAILS_STARTING";
export const GET_FOUNDATION_FORCE_POLE_DETAILS_SUCCESS =
  "GET_FOUNDATION_POLE_DETAILS_SUCCESS";
export const GET_FOUNDATION_FORCE_POLE_DETAILS_ERROR =
  "GET_FOUNDATION_POLE_DETAILS_ERROR";

export const ADD_FOUNDATION_FORCE_POLE_DETAILS_STARTING =
  "ADD_FOUNDATION_POLE_DETAILS_STARTING";
export const ADD_FOUNDATION_FORCE_POLE_DETAILS_SUCCESS =
  "ADD_FOUNDATION_POLE_DETAILS_SUCCESS";
export const ADD_FOUNDATION_FORCE_POLE_DETAILS_ERROR =
  "ADD_FOUNDATION_POLE_DETAILS_ERROR";

export const UPDATE_FOUNDATION_FORCE_POLE_DETAILS_STARTING =
  "UPDATE_FOUNDATION_POLE_DETAILS_STARTING";
export const UPDATE_FOUNDATION_FORCE_POLE_DETAILS_SUCCESS =
  "UPDATE_FOUNDATION_POLE_DETAILS_SUCCESS";
export const UPDATE_FOUNDATION_FORCE_POLE_DETAILS_ERROR =
  "UPDATE_FOUNDATION_POLE_DETAILS_ERROR";

export const DEACTIVATE_FOUNDATION_FORCE_POLE_DETAILS_STARTING =
  "DEACTIVATE_FOUNDATION_POLE_DETAILS_STARTING";
export const DEACTIVATE_FOUNDATION_FORCE_POLE_DETAILS_SUCCESS =
  "DEACTIVATE_FOUNDATION_POLE_DETAILS_SUCCESS";
export const DEACTIVATE_FOUNDATION_FORCE_POLE_DETAILS_ERROR =
  "DEACTIVATE_FOUNDATION_POLE_DETAILS_ERROR";

export const GET_POLE_CABLE_LADDERS_STARTING =
  "GET_POLE_CABLE_LADDERS_STARTING";
export const GET_POLE_CABLE_LADDERS_SUCCESS = "GET_POLE_CABLE_LADDERS_SUCCESS";
export const GET_POLE_CABLE_LADDERS_ERROR = "GET_POLE_CABLE_LADDERS_ERROR";

export const ADD_POLE_CABLE_LADDER_STARTING = "ADD_POLE_CABLE_LADDER_STARTING";
export const ADD_POLE_CABLE_LADDER_SUCCESS = "ADD_POLE_CABLE_LADDER_SUCCESS";
export const ADD_POLE_CABLE_LADDER_ERROR = "ADD_POLE_CABLE_LADDER_ERROR";

export const UPDATE_POLE_CABLE_LADDER_STARTING =
  "UPDATE_POLE_CABLE_LADDER_STARTING";
export const UPDATE_POLE_CABLE_LADDER_SUCCESS =
  "UPDATE_POLE_CABLE_LADDER_SUCCESS";
export const UPDATE_POLE_CABLE_LADDER_ERROR = "UPDATE_POLE_CABLE_LADDER_ERROR";

export const DEACTIVATE_POLE_CABLE_LADDER_STARTING =
  "DEACTIVATE_POLE_CABLE_LADDER_STARTING";
export const DEACTIVATE_POLE_CABLE_LADDER_SUCCESS =
  "DEACTIVATE_POLE_CABLE_LADDER_SUCCESS";
export const DEACTIVATE_POLE_CABLE_LADDER_ERROR =
  "DEACTIVATE_POLE_CABLE_LADDER_ERROR";

export const GET_PEDESTAL_DESIGNS_STARTING = "GET_PEDESTAL_DESIGNS_STARTING";
export const GET_PEDESTAL_DESIGNS_SUCCESS = "GET_PEDESTAL_DESIGNS_SUCCESS";
export const GET_PEDESTAL_DESIGNS_ERROR = "GET_PEDESTAL_DESIGNS_ERROR";

export const ADD_PEDESTAL_DESIGN_STARTING = "ADD_PEDESTAL_DESIGN_STARTING";
export const ADD_PEDESTAL_DESIGN_SUCCESS = "ADD_PEDESTAL_DESIGN_SUCCESS";
export const ADD_PEDESTAL_DESIGN_ERROR = "ADD_PEDESTAL_DESIGN_ERROR";

export const UPDATE_PEDESTAL_DESIGN_STARTING =
  "UPDATE_PEDESTAL_DESIGN_STARTING";
export const UPDATE_PEDESTAL_DESIGN_SUCCESS = "UPDATE_PEDESTAL_DESIGN_SUCCESS";
export const UPDATE_PEDESTAL_DESIGN_ERROR = "UPDATE_PEDESTAL_DESIGN_ERROR";

export const DEACTIVATE_PEDESTAL_DESIGN_STARTING =
  "DEACTIVATE_PEDESTAL_DESIGN_STARTING";
export const DEACTIVATE_PEDESTAL_DESIGN_SUCCESS =
  "DEACTIVATE_PEDESTAL_DESIGN_SUCCESS";
export const DEACTIVATE_PEDESTAL_DESIGN_ERROR =
  "DEACTIVATE_PEDESTAL_DESIGN_ERROR";

export const GET_UTILIZATIONS_STARTING = "GET_UTILIZATIONS_STARTING";
export const GET_UTILIZATIONS_SUCCESS = "GET_UTILIZATIONS_SUCCESS";
export const GET_UTILIZATIONS_ERROR = "GET_UTILIZATIONS_ERROR";

export const ADD_UTILIZATION_STARTING = "ADD_UTILIZATION_STARTING";
export const ADD_UTILIZATION_SUCCESS = "ADD_UTILIZATION_SUCCESS";
export const ADD_UTILIZATION_ERROR = "ADD_UTILIZATION_ERROR";

export const UPDATE_UTILIZATION_STARTING = "UPDATE_UTILIZATION_STARTING";
export const UPDATE_UTILIZATION_SUCCESS = "UPDATE_UTILIZATION_SUCCESS";
export const UPDATE_UTILIZATION_ERROR = "UPDATE_UTILIZATION_ERROR";

export const DEACTIVATE_UTILIZATION_STARTING =
  "DEACTIVATE_UTILIZATION_STARTING";
export const DEACTIVATE_UTILIZATION_SUCCESS = "DEACTIVATE_UTILIZATION_SUCCESS";
export const DEACTIVATE_UTILIZATION_ERROR = "DEACTIVATE_UTILIZATION_ERROR";
